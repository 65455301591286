@import "../../utils/variables.scss";
.lang-switch {
    position:relative;
    width:var(--side-bar-width,64px);
    height:var(--side-bar-width,64px);
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color:var(--main-color-default);
    cursor: pointer;
    .globe-icon {
      width:20px;
      height:20px;
      @include small {
        width:16px;
        height:16px;
      }
    }
    & span {
      display:inline-block;
      text-align:center;
      width:2em;
      text-transform: uppercase;
      font-size: 13px;
      line-height: 19px;
      font-weight: 500;
      @include small {
        font-size: 10px;
        line-height: 10px;
        margin-bottom:10px;
      }
    }

}
