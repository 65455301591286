@import "../../utils/variables.scss";
.main-menu {
  position: fixed;
  z-index:10;
  top:0;
  left:0;
  height:100%;
  svg {
    stroke:var(--main-color-default);
  }
  .sidebar {
    position:absolute;
    z-index:12;
    top:0;
    left:0;
    width:var(--side-bar-width,64px);
    height:100%;
    border-right: 1px solid $grisClair;
    background-color:#f7f2ed;
    .menu-item {
      position:relative;
      width:var(--side-bar-width,64px);
      height:var(--side-bar-width,64px);
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color:var(--main-color-default);
      cursor: pointer;
      svg {
        width:24px;
        height:24px;
        @include small {
          width:18px;
          height:18px;
        }
      }
      opacity:1;
      transition:opacity 0.5s;
      &.active {
        opacity:0.2;
      }
      border-radius:16px;
      background-color: var(--main-color-default-transparent);
      transition: background-color 0.5s, border-radius 0.5s;
      &:hover {
        svg {
          stroke:#FFF;
        }
        border-radius:0;
        background-color: var(--main-color-default);
      }
    }
    .up {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }
    .down {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      pointer-events: none;
      >* {
        pointer-events: auto;
      }
    }
    .menu-contact {
      width:100%;
      height:150px;
      position:relative;
      &>div {
        position:absolute;
        bottom:var(--side-bar-width-negative,-64px);
        left:0;
        width:150px;
        padding:0 15px;
        box-sizing: border-box;
        height:var(--side-bar-width,64px);
        display:flex;
        justify-content: flex-start;
        align-items: center;
        transform-origin: top left;
        transform: rotate(-90deg);
        font-weight:600;
        @include small {
          font-size:14px;
        }
      }
      opacity:1;
      transition:opacity 0.5s;
      &.active {
        opacity:0.2;
      }
    }
    .menu-toggle {
      border:none;
      background:none;
    }
    a {
      color:var(--main-color-default);
    }
  }
}
