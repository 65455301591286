@import "../../utils/variables.scss";
.contact {
  @include pagePadding;
  width:100%;
  .contact-body {
    @include pageBody;
    .adresses {
      display:flex;
      @include small {
        flex-direction: column;
      }
      .adresse {
        padding:30px;
        box-sizing: border-box;
        @include small {
          padding:30px 0px;
          width:100%;
        }
        .adresses-titre {
          font-family:'Neue Metana Next Free Personal';
        }
      }
    }
  }
  @include pageCercle;
  opacity:0;
  transition: opacity 1s;
  &.loaded {
    opacity:1;
    .cercle {
      transform:translate3d(0,0,0);
    }
    .logo {
      opacity:1;
      transform:translate3d(0,0,0);
    }
  }
}
