@import "../../utils/variables.scss";
.footer {
  position:relative;
  box-sizing: border-box;
  padding-left:var(--side-bar-width,64px);
  width:100%;
  height:350px;
  background-color: $gris;
  display:flex;
  justify-content: space-around;
  align-items: center;
  color:#fff;
  background-image: url(./footer_deco.png);
  background-position: right bottom;
  background-repeat: no-repeat;
  font-size:0.8em;
  .logo {
    position:relative;
    top:0;
    left:0;
    width:100%;
    height:60px;
  }
  .desc {
    padding:10px 0;
    p {
      margin:0;
    }
    line-height:1.5;
  }
  .bb,.pv {
    height:70%;
    width:22%;
    opacity:0.5;
    &.active {
      opacity:1;
    }
    a {
      color:#FFF;
    }
    &.highlight logo {
      background-color:$pv;
      mix-blend-mode:multiply;
    }
  }
  .separateur {
    height:70%;
    width:2px;
    background-color: #fff2;
  }
  .reseaux {
    width:25%;
    .rs {
      margin-top:50px;
      svg {
        margin-right:15px;
        fill:#fff;
        stroke:none;
      }
    }
  }
  @include small {
    height:auto;
    flex-direction: column;
    padding-top:30px;
    .bb,.pv {
      box-sizing: border-box;
      height:auto;
      width:100%;
      max-width:300px;
      padding:15px;
      .logo {
        width:100%;
      }
    }
    .reseaux {
      box-sizing: border-box;
      height:auto;
      width:100%;
      max-width:300px;
      padding:15px;
      .rs {
        margin-top:30px;
        margin-bottom: 80px;
      }
    }
  }
}
