@import "../../utils/variables.scss";
.contact-form {
  form {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-sizing:border-box;
    width:100%;
    input, textarea {
      width:100%;
      box-sizing:border-box;
      margin:8px 0;
      border:none;
      border-bottom:1px solid #404040;
      background-color: #0000;
      line-height: 1.5;
      padding:10px;
      &:focus {
        outline:none;
      }
    }
    button {
      border:none;
      padding:10px 50px;
      border-radius:8px;
      background-color: var(--main-color-default);
      color:#FFF;
      &.valid {
        cursor:pointer;
      }
      span {
        opacity:0.5;
        &.valid {
          opacity: 1;
          cursor:pointer;
        }
        &.pending {
          animation: 0.7s infinite alternate pulse;
        }
      }
    }
    .contact-cols {
      width:100%;
      display:flex;
      justify-content: space-between;
      input {
        width:49%;
      }
    }
  }
}
