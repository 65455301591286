@import "../../utils/variables.scss";
.projet-tile {
  margin-bottom:50px;
  .thumbnail {
    position:relative;
    width:100%;
    border-radius:8px;
    overflow:hidden;
    img {
      display: block;
      width:100%;
      transform: scale(1);
      transform-origin: center;
      filter: saturate(0.7);
      transition:transform 0.5s, filter 0.5s;
    }
    .mask {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      display:flex;
      justify-content: center;
      align-items: center;
      mix-blend-mode:normal;
      transform: scale(1);
      transform-origin: center;
      opacity:0;
      transition:opacity 0.5s, transform 0.5s;
      .neige {
        position:absolute;
        opacity:0.1;
        top:0;
        left:0;
        width:400%;
        height:400%;
        background-image: url(../../assets/neige2.png);
      }
      svg {
        position:relative;
        width:111px;
        fill:var(--main-color-default);
        transform: scale(1);
        transform-origin: center;
        transition:transform 0.5s;
      }
    }
    .btn {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      display:flex;
      justify-content: center;
      align-items: center;
      transform: scale(1);
      transform-origin: center;
      opacity:0;
      transition:opacity 0.5s, transform 0.5s;
      svg {
        position:relative;
        width:111px;
        fill:var(--main-color-default);
        transform: scale(1);
        transform-origin: center;
        transition:transform 0.5s;
      }
    }
    &:hover {
      img {
        transform: scale(1.1);
        filter: saturate(0.54) blur(3px);
      }
      svg {
        transform: scale(1.1);
      }
      .btn{
        transform: scale(1.1);
        opacity:1;
      }
      .mask{
        transform: scale(1.1);
        opacity:1;
      }
    }
  }
  .titre {
    padding:0 20px;
    padding-top:20px;
    font-family:'Neue Metana Next Free Personal';
    font-size:20px;
  }
  .soustitre {
    padding:0 20px;
    padding-top:5px;
    font-size:14px;
    font-weight:500;
    line-height: 1.5;
  }
  @include small {
    .titre {
      padding:0 5px;
      padding-top:10px;
      font-size:16px;
    }
    .soustitre {
      padding:0 5px;
      padding-top:5px;
      font-size:12px;
    }
  }
}
