@import "../../utils/variables.scss";
.equipe {
  @include pagePadding;
  width:100%;
  .equipe-body {
    @include pageBody;
    .equipe-catalogue {
      font-weight:600;
    }
    button {
      background-color: #0000;
      border:none;
      color: var(--main-color-default);
      font-size:1em;
      font-weight:600;
      cursor:pointer;
    }
  }
  @include pageCercle;
  opacity:0;
  transition: opacity 1s;
  &.loaded {
    opacity:1;
    .cercle {
      transform:translate3d(0,0,0);
    }
    .logo {
      opacity:1;
      transform:translate3d(0,0,0);
    }
  }
}
