@import "../../utils/variables.scss";
.une {
  position:relative;
  @include pagePadding;
  .colonnes>div {
    opacity:0;
    transform: translate3d(0,30px,0);
  }
  &.ready .colonnes>div {
    opacity:1;
    transform: translate3d(0,0,0);
  }
  @for $i from 1 through 3 {
    .colonnes>div:nth-child(#{$i}) {
      transition: opacity 0.5s $i * 0.2s, transform 0.2s $i * 0.2s;
    }
  }
  .colonnes {
    position:relative;
    z-index:1;
    display:flex;
    justify-content: center;
    width:100%;
    .colonne-3 {
      width:33%;
      padding:15px;
      box-sizing: border-box;
    }
    .colonne-2 {
      width:50%;
      padding:15px;
      box-sizing: border-box;
    }
    .colonne-1 {
      width:100%;
      padding:15px;
      box-sizing: border-box;
    }
  }
  .cercle-wrapper{
    position:absolute;
    top:0;
    left:0;
    .cercle {
      top:-115px;
      left:-168px;
      transform:translate3d(0,50px,0);
      transition: transform 1s;
    }
  }
  .logo {
    top:60px;
    left:129px;
    width: 298px;
    height: 109px;
    opacity:0;
    transform:translate3d(50px,0,0);
    transition: transform 1s 0.5s, opacity 1s 0.5s;
  }
  .une-action {
    width:100%;
    display:flex;
    justify-content: center;
    .btn-catalog {
      background-color: var(--main-color-default);
      border:none;
      padding:15px;
      width:220px;
      text-align:center;
      border-radius: 15px;
      color:#FFF;
      cursor:pointer;
      span {
        display:inline-block;
        position:relative;
        width:0;
        height:1em;
        transition: width 0.5s;
        svg {
          transform-origin: center;
          transform:rotate(-20deg) scale(0.7);
          opacity:0;
          transition: transform 0.5s, opacity 0.5s;
          position:absolute;
          left:calc(0.5em - 5px);
          top:calc(0.5em - 18px);
          width:40px;
          height:40px;
          stroke:#fff;
          stroke-width:5px;
        }
      }
      &:hover {
        span {
          width:2em;
          svg {
            transform:rotate(0) scale(0.9);
            opacity:1;
          }
        }
      }
    }
  }
  opacity:0;
  transition: opacity 1s;
  &.loaded {
    opacity:1;
    .cercle {
      transform:translate3d(0,0,0);
    }
    .logo {
      opacity:1;
      transform:translate3d(0,0,0);
    }
  }
  @include small {
    .cercle-wrapper{
      .cercle {
        top:-80px;
        left:-100px;
      }
    }
    .logo {
      top:20px;
      left:5%;
      width: 90%;
      height: 80px;
    }
  }
}
