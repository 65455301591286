@import "../../utils/variables.scss";
.mentions {
  @include pagePadding;
  width:100%;
  .mentions-body {
    @include pageBody;
  }
  @include pageCercle;
  opacity:0;
  transition: opacity 1s;
  &.loaded {
    opacity:1;
    .cercle {
      transform:translate3d(0,0,0);
    }
    .logo {
      opacity:1;
      transform:translate3d(0,0,0);
    }
  }
}
