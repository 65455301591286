@import "../../utils/variables.scss";
.newsletter {
  h3 {
    font-family:'Neue Metana Next Free Personal';
    font-weight:normal;
    font-size:24px;
    margin-bottom:10px;
  }
  .texte {
    margin-bottom:25px;
    p {
      margin:0;
    }
  }
  form {
    display:inline-block;
    box-sizing:border-box;
    background-color: #1f1f1f;
    border-radius:8px;
    width:100%;
    padding:0 15px;
    overflow:hidden;
    &>svg {
      stroke:#888;
      position:relative;
      top:3px;
    }
    input {
      box-sizing:border-box;
      border:none;
      background-color: #1f1f1f;
      width:calc(100% - 44px);
      color:#FFF;
      padding:14px 15px 16px 15px;
      &:active, &:focus {
        border:none;
        outline: none;
      }
      &[disabled] {
        opacity: 0.5;
      }
    }
    button {
      border:none;
      background:none;
      padding:0;
      transition: opacity 0.5s;
      position:relative;
      top:3px;
      svg {
        stroke:var(--main-color-default);
        opacity:0.5;
        &.valid {
          opacity: 1;
          cursor:pointer;
        }
        &.pending {
          animation: 0.7s infinite alternate pulse;
        }
      }
    }
  }
}
.newsletter {
  h3 {
    font-size:18px;
  }
}
@keyframes pulse { from { opacity:1; } to { opacity:0; }  }
