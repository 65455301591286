@import "../../utils/variables.scss";
.diaporama {
  width:100%;
  user-select: none;
  .swiper {
    width: 100%;
    height: 800px;
    @include small {
      height: 150px;
    }
    .swiper-button-next, .swiper-rtl .swiper-button-prev {
      right: 30px;
      left: auto;
      @include small {
        right:15px;
      }
    }
    .swiper-button-prev, .swiper-rtl .swiper-button-next {
      right: auto;
      left: 30px;
      @include small {
        left:15px;
      }
    }
    .swiper-button-next.swiper-button-disabled, .swiper-button-prev.swiper-button-disabled {
      opacity: 0;
      cursor: auto;
      pointer-events: none;
    }
    .swiper-button-next, .swiper-button-prev {
      position: absolute;
      width: 50px;
      height: 50px;
      background-color:var(--main-color-default);
      border-radius: 25px;
      margin-top: calc(0px - (var(--swiper-navigation-size)/ 2));
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--swiper-navigation-color,var(--swiper-theme-color));
      background-image: url('../../assets/fleche_blanche.svg');
      background-position: center;
      background-size: 22px;
      background-repeat: no-repeat;
      opacity: 1;
      transition:opacity 0.5s;
      @include small {
        width: 30px;
        height: 30px;
        background-size: 18px;
        border-radius: 15px;
        top:calc(100% - 20px);
      }
    }
    .swiper-button-prev {
      transform:scaleX(-1)
    }
    .swiper-button-next::after, .swiper-rtl .swiper-button-prev::after {
      content: '';
    }
    .swiper-button-prev::after, .swiper-rtl .swiper-button-next::after {
      content: '';
    }
    .swiper-slide {
      text-align: center;
      font-size: 18px;
      height:100%;
      width:auto;
      img {
        height:100%;
        opacity:0.2;
        transition:opacity 0.5s;
        &.active {
          opacity:1;
        }
      }
    }
  }
  &.catalogue-diaporama {
    .swiper {
      .swiper-button-next, .swiper-button-prev {
        background-color:$grisCatalogue;
      }
    }
  }
}
