@import "../../utils/variables.scss";
.newsletter-drawer {
  position: fixed;
  z-index:11;
  top:0;
  left:var(--side-bar-width,64px);
  height:100vh;
  width: calc(100% - var(--side-bar-width,64px));
  transform: translate3d(-100%,0,0);
  transition: transform 0.5s;
  &.open {
    transform: translate3d(0,0,0);
  }
  background-color:#2f2827;
  color:#FFF;
  box-sizing: border-box;
  display:flex;
  justify-content: center;
  align-items: center;
  .newsletter-drawer-inner {
    width:500px;
    max-width: 90%;
    position:relative;
    &>svg {
      position:absolute;
      right:0;
      top:0;
      cursor:pointer;
    }
  }
}
