@import "../../utils/variables.scss";
.pagination {
  position:relative;
  width:100%;
  display: flex;
  justify-content: center;
  .pagination-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    & span.chevron {
      cursor:pointer;
      padding:10px 5px;
      user-select: none;
      &.left {
        transform: scaleX(-1);
      }
    }
    & span.page-dot {
      cursor:pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width:36px;
      height:36px;
      border-radius:50px;
      margin:0 5px;
      color:$gris;
      text-align:center;
      user-select: none;
      min-height: 0;
      &.active {
        background-color:$gris;
        color:#FFF;
      }
      font-weight:600;
    }
  }
}
