@import "../../utils/variables.scss";
.drawer {
  position: fixed;
  z-index:11;
  top:0;
  left:var(--side-bar-width,64px);
  height:100vh;
  width: calc(100% - var(--side-bar-width,64px));
  transform: translate3d(-100%,0,0);
  transition: transform 0.5s;
  &.open {
    transform: translate3d(0,0,0);
  }
  background-color:#2f2827;
  color:#FFF;
  box-sizing: border-box;
  padding:50px;
  overflow-y:scroll;
  .titre {
    font-family:'Neue Metana Next Free Personal';
    font-size:60px;
  }
  .texte {
    font-size:40px;
    line-height:1.5;
  }
  .menu-chip {
    display:inline-block;
    color:#fff;
    background-color: var(--main-color-default);
    padding:0.2em 0.8em;
    border-radius: 1em;
    line-height:1.2em;
    text-transform: uppercase;
    font-size:0.7em;
    font-weight: 600;
    border:none;
    cursor:pointer;
    &:hover, &:active {
      filter:saturate(2);
    }
  }
  .menu-chip-newsletter {
    display:inline-block;
    color:#fff;
    background-color: #2f2827;
    padding:5px 20px;
    line-height:1em;
    border-radius: 1em;
    text-transform: uppercase;
    font-size:0.8em;
    font-weight: 600;
    border:none;
    cursor:pointer;
    &:hover, &:active {
      filter:saturate(6);
    }
  }
  @include small {
    padding:15px;
    .titre {
      font-size:24px;
    }
    .texte {
      font-size:20px;
      line-height:1.5;
    }
    .menu-chip {
      svg {
        position:relative;
        top:0.15em;
        width:1em;
        height:1em;
      }
    }
  }
  @include medium {
    padding:30px;
    .titre {
      font-size:40px;
    }
    .texte {
      font-size:30px;
      line-height:1.5;
    }
    .menu-chip {
      svg {
        position:relative;
        top:0.15em;
        width:1em;
        height:1em;
      }
    }
  }
}
