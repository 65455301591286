@import "../../utils/variables.scss";
.cercle {
  &.color{
    background-color: var(--main-color-default);
  }
  border-radius: 600px;
  flex: 0 0 auto;
  height: 498px;
  left: -248px;
  overflow: hidden;
  position: absolute;
  top: -285px;
  transition: top 0.5s;
  width: 498px;
  z-index:0;
  .texture {
    aspect-ratio: 1.37364 / 1;
    bottom: -91px;
    flex: 0 0 auto;
    height: 532px;
    left: -25px;
    mix-blend-mode: multiply;
    opacity: 0.16;
    overflow: visible;
    position: absolute;
    right: -208px;
    background-image: url('./texture.jpg');
    background-position: center;
    background-size: cover;
  }
  @include small {
    top: -100px;
    left: -100px;
    height: 200px;
    width: 200px;
  }
}
