@import "./utils/variables.scss";
.page {
  min-height:100vh;
  padding-left:calc( var(--side-bar-width,64px) + 1px);
  display:flex;
  justify-content: center;
  overflow-x: hidden;
}
.spacer {
  width:100%;
  height:60px;
  &.spacer-catalogue {
    height:69px;
    @include small {
      height:30px;
    }
  }
}
.spacer-small {
  height:15px;
}
a {
  text-decoration: none;
  color: var(--main-color-default);
}
svg {
  stroke: var(--main-color-default);
}
img {
  max-width:100%;
}
.svg-color-pv {
  stroke:$pv;
}
.svg-color-bb {
  stroke:$bb;
}
.main-header{
  position:relative;
  left:calc(50% - 50vw + var(--side-bar-width-half));
}
