body, html {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#f7f2ed;
  font-family:'Figtree', sans;
  font-size:16px;
  color:#303030;
  overflow-x: hidden;
}
@font-face {
    font-family: 'Figtree';
    src: url('./assets/Figtree-Black.woff2') format('woff2'),
        url('./assets/Figtree-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: url('./assets/Figtree-BlackItalic.woff2') format('woff2'),
        url('./assets/Figtree-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: url('./assets/Figtree-Bold.woff2') format('woff2'),
        url('./assets/Figtree-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: url('./assets/Figtree-BoldItalic.woff2') format('woff2'),
        url('./assets/Figtree-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: url('./assets/Figtree-ExtraBold.woff2') format('woff2'),
        url('./assets/Figtree-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: url('./assets/Figtree-Italic.woff2') format('woff2'),
        url('./assets/Figtree-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: url('./assets/Figtree-ExtraBoldItalic.woff2') format('woff2'),
        url('./assets/Figtree-ExtraBoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: url('./assets/Figtree-Light.woff2') format('woff2'),
        url('./assets/Figtree-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: url('./assets/Figtree-Medium.woff2') format('woff2'),
        url('./assets/Figtree-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: url('./assets/Figtree-LightItalic.woff2') format('woff2'),
        url('./assets/Figtree-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: url('./assets/Figtree-MediumItalic.woff2') format('woff2'),
        url('./assets/Figtree-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: url('./assets/Figtree-Regular.woff2') format('woff2'),
        url('./assets/Figtree-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: url('./assets/Figtree-SemiBoldItalic.woff2') format('woff2'),
        url('./assets/Figtree-SemiBoldItalic.woff') format('woff');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Figtree';
    src: url('./assets/Figtree-SemiBold.woff2') format('woff2'),
        url('./assets/Figtree-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Neue Metana Next Free Personal';
    src: url('./assets/NeueMetanaNextFreePersonal-Sm.woff2') format('woff2'),
        url('./assets/NeueMetanaNextFreePersonal-Sm.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}
