.logo {
  position:absolute;
  z-index:2;
  top:60px;
  left:129px;
  width: 240px;
  height: 82px;
  &.logo-bb {
    background-image: url('./logo.png');
    &.logo-blanc {
      background-image: url('./logo_blanc.png');
    }
    &.logo-highlight {
      background-image: url('./logo_highlight.png');
    }
    background-position: left center;
    background-size: contain;
    background-repeat: no-repeat;
  }
  &.logo-pv {
    background-image: url('./logo_pv.png');
    &.logo-blanc {
      background-image: url('./logo_pv_blanc.png');
    }
    &.logo-highlight {
      background-image: url('./logo_pv_highlight.png');
    }
    background-position: left center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
