$rouge: #fd4700;
$gris: #303030;
$grisClair: #d7d7d7;
$grisMedium: #ababab;
$blanc: #FFFFFF;
$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
$screen-xxl-min: 1600px;
$bb: #4e5dff;
$pv: #fd4700;
$grisCatalogue: #666;
$grisCatalogueTransparent: #6660;

@mixin pagePadding {
  position:relative;
  width:100%;
  max-width:768px;
  @media (min-width: #{$screen-md-min}) {
    max-width:680px;
  }
  @media (min-width: #{$screen-lg-min}) {
    max-width:850px;
  }
  @media (min-width: #{$screen-xl-min}) {
    max-width:1082px;
  }
  @media (min-width: #{$screen-xxl-min}) {
    max-width:1200px;
  }
}
@mixin small {
  .xs &, .sm & {
    @content;
  }
}
@mixin medium {
  .md &, .lg & {
    @content;
  }
}
@mixin pageCercle {
  .cercle {
    transform:translate3d(0,50px,0);
    transition: transform 1s;
  }
  .logo {
    opacity:0;
    transform:translate3d(50px,0,0);
    transition: transform 1s 0.5s, opacity 1s 0.5s;
  }
  @include small {
    .cercle {
      top:-80px;
      left:-100px;
    }
    .logo {
      top:20px;
      left:5%;
      width: 90%;
      height: 80px;
    }
  }
}

@mixin pageBody {
  margin-top:250px;
  width:72%;
  padding-right:30px;
  box-sizing: border-box;
  .titre {
    position:relative;
    font-family:'Neue Metana Next Free Personal';
    font-size:60px;
    margin-bottom:40px;
  }
  .texte {
    position:relative;
    font-weight: 500;
    line-height:2;
    iframe {
      width:100%;
      aspect-ratio:16/9;
    }
  }
  @include small {
    margin-top:150px;
    width:100%;
    padding: 0 10px;
    .titre {
      font-size:30px;
      margin-bottom:20px;
    }
    .texte {
      font-size:14px;
      line-height:1.5;
      p[style*="justify"], div[style*="justify"] {
        text-align:left !important;
      }
    }
  }
}
