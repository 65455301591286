@import "../../utils/variables.scss";
.titre-anime {
  .mot{
    display: inline-block;
    &::after {
      display: inline-block;
      content:' '
    }
    &:last-child::after {
      content:none;
    }
    opacity:0;
    transform: translate3d(0,15px,0);
    @for $i from 1 through 50 {
      &:nth-child(#{$i}) {
        transition: transform 0.7s $i*0.2s, opacity 0.5s $i*0.2s;
      }
    }
  }
  &.loaded {
    .mot{
      transform: translate3d(0,0,0);
      opacity:1;
    }
  }
}
