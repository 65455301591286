@import "../../utils/variables.scss";
.projet {
  width:100%;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  position:relative;
  .projet-body {
    @include pagePadding;
    display:flex;
    @include small {
      flex-direction: column;
    }
    .gauche {
      margin-top:250px;
      width:72%;
      padding-right:30px;
      box-sizing: border-box;
      border-right: 1px solid $grisMedium;
      .titre {
        position:relative;
        font-family:'Neue Metana Next Free Personal';
        font-size:60px;
        margin-bottom:40px;
      }
      .soustitre {
        position:relative;
        font-weight: 600;
        font-size:20px;
        margin-bottom:35px;
      }
      .texte {
        position:relative;
        font-weight: 500;
        line-height:2;
        iframe {
          width:100%;
          aspect-ratio:16/9;
        }
      }
      @include small {
        margin-top:150px;
        width:100%;
        border:none;
        padding:0 10px;
        .titre {
          font-size:30px;
          margin-bottom:40px;
        }
        .texte {
          line-height:1.5;
          p[style*="justify"], div[style*="justify"] {
            text-align:left !important;
          }
        }
      }
    }
    .droite {
      margin-top:250px;
      box-sizing: border-box;
      padding-left:70px;
      width:28%;
      .meta {
        position:relative;
        margin-top:150px;
        margin-bottom:70px;
        .meta-key {
          font-family:'Neue Metana Next Free Personal';
          color:var(--main-color-default);
          margin-top:45px;
          margin-bottom:8px;
          font-size:18px;
        }
        .meta-value {
          font-weight:300;
          line-height:1.7;
          white-space: pre-wrap;
        }
      }
      .download-btn {
        border:none;
        background:none;
        color:var(--main-color-default);
        font-weight:600;
        font-family:'Figtree';
        padding:0;
        font-size:18px;
        cursor:pointer;
        &:active {
          opacity:0.7;
        }
        svg {
          stroke:var(--main-color-default);
          display:inline-block;
          position:relative;
          top:3px;
          width:20px;
          height:20px;
        }
      }
      @include small {
        margin-top:30px;
        box-sizing: border-box;
        padding:10px;
        width:100%;
        .meta {
          margin-top:0;
        }
      }
    }
  }
  @include pageCercle;
  opacity:0;
  transition: opacity 1s;
  &.loaded {
    opacity:1;
    .cercle {
      transform:translate3d(0,0,0);
    }
    .logo {
      opacity:1;
      transform:translate3d(0,0,0);
    }
  }
  .nav {
    display:flex;
    .nav-prev, .nav-next, .nav-catalogue {
      box-sizing: border-box;
      height:var(--side-bar-width,64px);
      padding:0 30px;
      background-color: var(--main-color-default-transparent);
      border-radius:30px;
      color:#FFF;
      margin: 0 10px;
      font-weight:600;
      display:flex;
      justify-content: center;
      align-items: center;
      color:var(--main-color-default);
      opacity:0.5;
      transition: background-color 0.5s, border-radius 0.5s, color 0.5s, opacity 0.5s;
      &.active, &.nav-catalogue {
        opacity:1;
      }
      &.active:hover, &.nav-catalogue:hover {
        background-color: var(--main-color-default);
        border-radius:15px;
        color:#FFF;
      }
    }
    .nav-prev {
      svg {
        margin-right: 10px;
        transform:scaleX(-1) translate3d(0,0,0);
        transition: transform 0.5s, stroke 0.5s;
        stroke:var(--main-color-default);
      }
      &.active:hover {
        svg {
          transform:scaleX(-1) translate3d(5px,0,0);
          stroke:#fff;
        }
      }
    }
    .nav-next {
      svg {
        margin-left: 10px;
        transition: transform 0.5s, stroke 0.5s;
        stroke:var(--main-color-default);
      }
      &.active:hover {
        svg {
          transform:translate3d(5px,0,0);
          stroke:#fff;
        }
      }
    }
  }
  &.catalogue-projet {
    .droite {
      .meta {
        .meta-key {
          color:$grisCatalogue;
        }
      }
      .download-btn {
        color:$grisCatalogue;
        svg {
          stroke:$grisCatalogue;
        }
      }
    }
    .nav-prev, .nav-next, .nav-catalogue {
      background-color: $grisCatalogueTransparent;
      color:$grisCatalogue;
      &.active:hover, &.nav-catalogue:hover {
        background-color: $grisCatalogue;
      }
    }
    .nav-prev {
      svg {
        stroke:$grisCatalogue;
      }
    }
    .nav-next {
      svg {
        stroke:$grisCatalogue;
      }
    }
    a {
      color:#000;
      text-decoration: underline;
    }
    .nav a {
      text-decoration: none;
    }
  }
}
