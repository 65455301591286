@import "../../utils/variables.scss";
.catalogue {
  opacity:0;
  @include pagePadding;
  @include medium {
    width:90%;
    max-width:90%;
  }
  transition: opacity 1s;
  @include pageCercle;
  .header {
    position:relative;
    z-index:1;
    height:98px;
    width:100%;
    display: flex;
    padding-top:70px;
    transform:translate3d(50px,0,0);
    opacity:0;
    transition: transform 1s 0.5s, opacity 1s 0.5s;
    .main-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-family:'Neue Metana Next Free Personal';
      font-size:50px;
      padding-right:24px;
      border-right: 2px solid $grisClair;
    }
    .main-subtitle {
      display: flex;
      padding-left:28px;
      flex-direction: column;
      justify-content: center;
      font-weight:600;
      font-size:14px;
      line-height:1.4;
    }
    @include small {
      flex-direction: column;
      .main-title {
        font-size:30px;
        border:none;
        width:100%;
        padding:0 10px;
      }
      .main-subtitle {
        padding-left:10px;
      }
    }
  }
  .recherche {
    position:relative;
    top:-8px;
    display:flex;
    justify-content: flex-end;
    svg {
      flex-shrink: 0;
      width:19px;
      height:40px;
      margin-left:19px;
    }
    .input {
      background-color:$gris;
      border-radius:30px;
      display:flex;
      align-items: center;
      padding:0 12px;
      width:160px;
      height:37px;
      box-sizing: border-box;
      transition: width 0.5s;
    }
    &.open {
      .input {
        width:250px;
      }
    }
    @include small {
      top:0;
      margin:10px;
    }
  }
  .catalog-header {
    display: flex;
    justify-content: space-between;
    width:100%;
    min-width:1100px;
    margin-bottom:10px;
    font-size:0.8em;
    svg {
      margin-left:3px;
      width:12px;
      opacity:0.2;
      transform:rotate(0);
      transition:transform 0.5s, opacity 0.5s;
      &.active {
        opacity:1;
      }
      &.up {
        transform:rotate(-180deg);
      }
    }
    &>div {
      cursor:pointer;
    }
    .visuel {
      position:relative;
      width:238px;
      flex-shrink: 0;
      padding:0;
      box-sizing: border-box;
    }
    .titre {
      width:250px;
      flex-shrink: 0;
      padding:0 32px;
      box-sizing: border-box;
    }
    .auteur {
      width:200px;
      flex-shrink: 0;
      padding:0 30px;
      box-sizing: border-box;
    }
    .date {
      width:120px;
      flex-shrink: 2;
      padding:0 30px;
      box-sizing: border-box;
    }
    .type {
      width:250px;
      flex-shrink: 0;
      padding:0 30px;
      box-sizing: border-box;
    }
    @include small {
      display: flex;
      justify-content: space-around;
      width:100%;
      min-width:100%;
      .visuel {
        position:relative;
        width:auto;
        flex-shrink: 0;
        padding:0;
        box-sizing: border-box;
      }
      .titre {
        width:auto;
        flex-shrink: 0;
        padding: 0 5px;
        box-sizing: border-box;
      }
      .auteur {
        width:auto;
        flex-shrink: 0 5px;
        padding:0;
        box-sizing: border-box;
      }
      .date {
        width:auto;
        flex-shrink: 0;
        padding: 0 5px;
        box-sizing: border-box;
      }
      .type {
        width:auto;
        flex-shrink: 0;
        padding: 0 5px;
        box-sizing: border-box;
      }
    }
  }
  .lines {
    position:relative;
    width:100%;
    padding:0 15px;
    box-sizing: border-box;
    .projet-line {
      opacity:0;
      transform:translate3d(0,30px,0);
    }
    @for $i from 2 through 16 {
      & a:nth-child(#{$i}) .projet-line {
        transition: opacity 0.5s $i * 0.1s, transform 0.5s $i * 0.1s;
      }
    }
  }
  &.ready {
    .lines {
      .projet-line {
        transform:translate3d(0,0,0);
        opacity:1;
      }
    }
  }
  &.loaded {
    opacity:1;
    .cercle {
      transform:translate3d(0,0,0);
    }
    .header {
      opacity:1;
      transform:translate3d(0,0,0);
    }
  }
}
