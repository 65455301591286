@import "../../utils/variables.scss";
a.projet-line-link {
  text-decoration:none;
  color:inherit;
}
.projet-line {
  height:131px;
  width:100%;
  box-sizing: border-box;
  border-radius:8px;
  background-color: #fff;
  margin-bottom:20px;
  display:flex;
  justify-content: space-between;
  overflow:hidden;
  .thumbnail {
    position:relative;
    width:238px;
    height:100%;
    overflow:hidden;
    flex-shrink: 0;
    img {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .titre {
    position:relative;
    width:250px;
    height:100%;
    overflow:hidden;
    flex-shrink: 0;
    padding:20px 32px;
    font-family:'Neue Metana Next Free Personal';
    font-size:17px;
    display:flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;

  }
  .auteur {
    position:relative;
    width:200px;
    height:100%;
    overflow:hidden;
    padding:20px 30px;
    flex-shrink: 0;
    font-weight:600;
    display:flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
  .date {
    position:relative;
    width:120px;
    height:100%;
    overflow:hidden;
    padding:20px 30px;
    font-weight:600;
    display:flex;
    flex-shrink: 2;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
  .type {
    position:relative;
    width:250px;
    height:100%;
    overflow:hidden;
    padding:20px 30px;
    font-weight:600;
    display:flex;
    flex-shrink: 0;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
  }
  @include medium {
    height:100px;
    font-size:12px;
    .thumbnail {
      position:relative;
      width:181px;
      height:100%;
      overflow:hidden;
      flex-shrink: 0;
      img {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        object-fit: cover;
        object-position: center;
      }
    }
    .titre {
      position:relative;
      width:180px;
      height:100%;
      overflow:hidden;
      flex-shrink: 0;
      padding:10px;
      font-family:'Neue Metana Next Free Personal';
      font-size:17px;
      display:flex;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;

    }
    .auteur {
      position:relative;
      width:120px;
      height:100%;
      overflow:hidden;
      padding:10px;
      flex-shrink: 0;
      font-weight:600;
      display:flex;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;
    }
    .date {
      position:relative;
      width:50px;
      height:100%;
      overflow:hidden;
      padding:10px;
      font-weight:600;
      display:flex;
      flex-shrink: 0;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;
    }
    .type {
      position:relative;
      width:120px;
      height:100%;
      overflow:hidden;
      padding:10px;
      font-weight:600;
      display:flex;
      flex-shrink: 0;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;
    }

  }
  @include small {
    width:100%;
    height:auto;
    min-width:100%;
    box-sizing: border-box;
    border-radius:8px;
    background-color: #fff;
    margin-bottom:20px;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    overflow:hidden;
    .thumbnail {
      position:relative;
      width:100%;
      padding-bottom:50%;
      overflow:hidden;
      flex-shrink: 0;
      img {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        object-fit: cover;
        object-position: center;
      }
    }
    .titre {
      position:relative;
      width:100%;
      height:auto;
      overflow:hidden;
      flex-shrink: 0;
      padding:20px 20px 10px 20px;
      font-family:'Neue Metana Next Free Personal';
      font-size:17px;
      display:flex;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;
    }
    .auteur {
      position:relative;
      width:100%;
      height:auto;
      overflow:hidden;
      padding:0 20px 10px 20px;
      flex-shrink: 0;
      font-weight:600;
      display:flex;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;
    }
    .date {
      position:relative;
      width:100%;
      height:auto;
      overflow:hidden;
      padding:0 20px 10px 20px;
      font-weight:600;
      display:flex;
      flex-shrink: 2;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;
    }
    .type {
      position:relative;
      width:100%;
      height:auto;
      overflow:hidden;
      padding:0 20px 20px 20px;
      font-weight:600;
      display:flex;
      flex-shrink: 0;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;
    }
  }
}
